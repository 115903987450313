import {
    CustomDimension,
    TrackEventParams,
    TrackLinkParams,
    TrackPageViewParams,
    TrackParams,
} from './types';

export const TRACK_TYPES = {
    TRACK_EVENT: 'trackEvent',
    TRACK_LINK: 'trackLink',
    TRACK_SEARCH: 'trackSiteSearch',
    TRACK_VIEW: 'trackPageView',
    TRACK_ECOMMERCE_ORDER: 'trackEcommerceOrder',
    TRACK_ECOMMERCE_CART_UPDATE: 'trackEcommerceCartUpdate',
};

declare global {
    interface Window {
        _paq: [string, ...any[]][];
    }
}

export const trackEvent = ({
    category,
    action,
    name,
    value,
    ...otherParams
}: TrackEventParams): void => {
    if (category && action) {
        track({
            data: [TRACK_TYPES.TRACK_EVENT, category, action, name, value],
            ...otherParams,
        })
    } else {
        throw new Error(`Error: category and action are required.`)
    }
};

const trackLink = ({ href, linkType = 'link' }: TrackLinkParams): void => {
    pushInstruction(TRACK_TYPES.TRACK_LINK, href, linkType)
};

const trackPageView = (params?: TrackPageViewParams): void => {
    track({ data: [TRACK_TYPES.TRACK_VIEW], ...params })
};

const track = ({
    data = [],
    documentTitle = window.document.title,
    href,
    customDimensions = false,
}: TrackParams): void => {
    if (data.length) {
        if (
            customDimensions &&
            Array.isArray(customDimensions) &&
            customDimensions.length
        ) {
            customDimensions.map((customDimension: CustomDimension) =>
                pushInstruction(
                    'setCustomDimension',
                    customDimension.id,
                    customDimension.value,
                ),
            )
        }

        pushInstruction('setCustomUrl', href ?? window.location.href)
        pushInstruction('setDocumentTitle', documentTitle)
        pushInstruction(...(data as [string, ...any[]]))
    }
};

const pushInstruction = (name: string, ...args: any[]) => {
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line
        window._paq.push([name, ...args])
    }
};

interface MatomoTrackerCodeProps {
    baseUrl: string;
    siteId: number;
    trackerFileName: string;
}

export const MatomoTrackerCode: React.FC<MatomoTrackerCodeProps> = ({ baseUrl, siteId, trackerFileName }) => {
    return (
        <>
            <script dangerouslySetInnerHTML={{
                __html: `
                    var _paq = window._paq = window._paq || [];
                    _paq.push(['enableHeartBeatTimer']);
                    _paq.push(['enableLinkTracking']);
                    _paq.push(['setTrackerUrl', '${baseUrl}/${trackerFileName}.php']);
                    _paq.push(['setSiteId', ${siteId}]);
                `
            }}></script>
            <script type="text/javascript" async defer src={`${baseUrl}/${trackerFileName}.js`}></script>
        </>
    );
};

export default {
    MatomoTrackerCode,
    trackEvent,
    trackLink,
    trackPageView,
    pushInstruction
};