import React, { useContext, useEffect, useState } from 'react';
import StyledButton from '../../components/StyledButton';
import { TopicV2 } from '@kazvabg/voterapp-model';
import { generateTopicSlug } from '../../util/util';
import { AppContext } from '../../App';
import axios, { AxiosResponse } from 'axios';
import TopicCard from '../../components/TopicCard';

import Fade from '../../components/Fade';
import { animated, useSpring } from 'react-spring';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';

interface VoteEndProps {
    resetVote: () => void;
}

const VoteEnd: React.FC<VoteEndProps> = ({ resetVote }) => {

    const { apiUrl, topic, setGlassPanelBackdropFilter } = useContext(AppContext);
    const { topicHash }: { topicHash: string } = useParams();

    const [topics, setTopics] = useState<TopicV2[] | null>(null);

    const history = useHistory();

    const { t } = useTranslation();

    const [voteEndContainerStyle, voteEndContainerApi] = useSpring(() => ({
        opacity: 1
    }));

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (!topic)
            return;

        setGlassPanelBackdropFilter(0, 1, 1);

        axios.get(`${apiUrl}/topic/${generateTopicSlug(topic.name)}/next`).then((res: AxiosResponse) => {
            setTopics(res.data.nextTopics);
        });
    }, []);

    const topicCardClicked = (topic: TopicV2) => {
        voteEndContainerApi.start({
            opacity: 0,
            config: { duration: 300 },
            onRest: () => {
                if (topic) {
                    resetVote();
                    history.push(generateTopicSlug(topic.name));
                }
            }
        });
    };

    const copyTopicLink = () => {

        if (!topic) return;

        const el = document.createElement('textarea');
        el.value = `https://m.kazva.bg/m/${topicHash}.html?s=share`
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 5000);

    };

    const shareTopic = () => {
        const shareData = {
            // title: "Kazva BG",
            // text: "Виж какво казват другите спрямо твоето мнение с kazva bg.",
            url: `https://m.kazva.bg/m/${topicHash}.html?s=share`,
        };
        navigator.share(shareData);
    };

    return (
        <animated.div style={voteEndContainerStyle}>
            <Fade cascade>
                {!topic?.pvt.showCopyLink ? null :
                    navigator.share === undefined ?
                        <StyledButton disabled={copied} onClick={copyTopicLink}>
                            {copied ?
                                <>
                                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faCheck} /></span>
                                    {t('end_screen_copied_link')}
                                </>
                                : t('end_screen_copy_link')
                            }
                        </StyledButton> :
                        <StyledButton onClick={shareTopic}>
                            <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faShare} /></span>
                            {t('end_screen_share_topic')}
                        </StyledButton>
                }
                {!topic?.pvt.showVoteAgain ? null : <StyledButton onClick={resetVote}>{t('end_screen_vote_again')}</StyledButton>}
                {!topics ? null : topics.map((topic, i) => <TopicCard key={i} topic={topic} onClick={() => topicCardClicked(topic)} />)}
            </Fade>
        </animated.div>
    );
};

export default VoteEnd;