import { useContext } from "react";

import { AppContext, MenuScreen } from "../App";

import PrivacyPolicy from "../components/PrivacyPolicy";


const PolicyScreen: React.FC<{ prevScreen: MenuScreen }> = (props) => {

    const { setMenuScreen } = useContext(AppContext);

    return (
        <PrivacyPolicy backClicked={() => setMenuScreen(props.prevScreen)} />
    );
};

export default PolicyScreen;