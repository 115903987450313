import { useContext } from "react";

import { AppContext, MenuScreen } from "../App";

import styled from "styled-components";
import StyledButton from "../components/StyledButton";
import StyledContentPanel from "../components/StyledContentPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { useTranslation } from "react-i18next";

const PolicyScreenStyle = styled(StyledContentPanel)`
    h1 {
        margin: 20px 0;
        font-size: 24px;
    }

    h2 {
        margin: 20px 0;
        font-size: 17px;//24px;
    }

    h3 {
        margin: 20px 0;
        font-size: 17px;
    }

    h4 {
        margin: 20px 0;
        font-size: 15px;
    }

    p, li {
        font-size: 15px;
        color: white;
        margin-top: 15px;
        margin-bottom: 0;
        line-height: 1.7;
        font-weight: normal;
    }
`;

interface PolicyScreenProps {
    backClicked: () => void;
}

const PolicyScreen: React.FC<PolicyScreenProps> = (props) => {

    const { t } = useTranslation();

    return (
        <div style={{ padding: '20px' }}>
            <StyledButton onClick={props.backClicked}>
                <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faArrowLeft} /></span>
                {t('button_back')}
            </StyledButton>
            <PolicyScreenStyle style={{ marginBottom: '20px' }}>
                <h1>Политика за защита на личните данни на ЦНТС ЕООД за kazva.bg</h1>
                <h2>ЗА НАС</h2>
                <p>
                    „ЦНТС“ е компания, предлагаща цялостно обслужване в сферата на маркетинговите и социални изследвания и консултации. Компанията създаде и 
                    оперира kazva.bg, с цел предоставяне на инструмент на всеки желаещ да изрази бързо и удобно своето мнение по дадена тема и да види как 
                    неговото/нейното мнение се сравнява с това на другите. Когато темата, по която се събира обратна връзка и мнение, касае продукти, услуги 
                    или инициативи на трета страна, клиент на ЦНТС, kazva.bg предоставя възможност на индивидуалните потребители тяхното анонимизирано мнение 
                    да бъде ефективно чуто директно от служителите на високо ниво в дадената организация. Може да прочете повече за нас отправите 
                    своето бизнес запитване на cnts.bg.
                </p>
                <h2>НОРМАТИВНО ОСНОВАНИЕ</h2>
                <p>Изготвянето на политиката е съобразено с изискванията на:</p>
                <ul>
                    <li>Закона за защита на личните данни (ЗЗЛД)</li>
                    <li>Общ регламент за защита на личните данни (Регламент (ЕС) 2016/679).</li>
                </ul>
                <h2>ЦЕЛ НА ПОЛИТИКАТА</h2>
                <p>Да определи правилата по отношение на защитата на физически лица във връзка с обработването на лични данни, като посочи:</p>
                <ul>
                    <li>Видовете лични данни, които се обработват;</li>
                    <li>Начин на събиране на личните данни;</li>
                    <li>Къде следва да се предоставят личните данни и на кого;</li>
                    <li>Как се защитават и съхраняват личните данни.</li>
                    <li>Да защити основните права и свободи на физическите лица, и по-специално тяхното право на защита на личните данни.</li>
                </ul>
                <h2>ВИДОВЕ ЛИЧНИ ДАННИ, КОИТО СЕ ОБРАБОТВАТ</h2>
                <p>
                    Компанията събира, съхранява и използва само и единствено данни, които всеки един потребител на платформата сам реши да предостави, 
                    като тези данни могат да бъдат следните видове информация за физически лица: лични данни за контакт като име, адреси, телефонни 
                    номера, служебни и лични имейл адреси. Също така година на раждане и демографски характеристики (пол, възраст, населено място).
                </p>
                <h2>НАЧИН НА СЪБИРАНЕ НА ДАННИТЕ</h2>
                <p>
                    Ние събираме информация от Вас, когато се регистрирате на kazva.bg, отговаряте на въпроси посредством kazva.bg, или попълвате своя профил 
                    в kazva.bg, предоставяйки ни обратна връзка за продукти или услуги на наши клиенти (юридически лица явяващи се трета страна) или споделяйки 
                    мнението си по теми и въпроси администрирани директно от екипа на kazva.bg.
                </p>
                <h3>КАК ИЗПОЛЗВАМЕ ВАШАТА ИНФОРМАЦИЯ?</h3>
                <p>
                    kazva.bg има за цел да подобри нивото на ефективна комуникация между всеки индивидуален ползвател на инструмента и организацията (клиент на kazva.bg), 
                    която събира обратна връзка от индивидуалния потребител относно своите продукти, услуги и инициативи. Всеки потребител на kazva.bg е с анонимизирани по 
                    подразбиране данни, освен в случаите, в които дадения потребител изрично и по своя воля пожелае да разкрие свои лични данни, за да може организацията 
                    клиент на kazva.bg да е в състояние да се свърже с дадения индивидуален потребител и разреши посочен казус, предостави 
                    предложената награда, намаление или друго. 
                </p>
                <p>
                    За да достави услугата на всеки един от индивидуалните потребители на kazva.bg мнението му/й да бъде чуто от ръководството на съответната организация 
                    клиент на системата, kazva.bg предоставя анонимизиран достъп до аналитика, статистика и съдържание генерирано от индивидуалните потребители на kazva.bg, 
                    касаеща само и единствено мнения изразени конкретно по теми, продукти, услуги и инициативи ясно маркирани като отнасящи се до конкретната организация, 
                    посредством поставянето на логото на организацията на видно място в интерфейса на kazva.bg, посредством който индивидуалните потребители изразяват своето 
                    мнение. Всички тези данни и анализи са достъпни само за ЦНТС ЕООД и подбрани служители на съответния за всяка една конкретна тема клиент на ЦНТС ЕООД.
                </p>
                <p>
                    Може да използваме информацията, която събираме от Вас, когато се регистрирате, по следните начини:
                </p>
                <ul>
                    <li>За да персонализираме вашето изживяване и да ни позволите да предоставим типа съдържание, от което се интересувате най-много.</li>
                    <li>За да подобрим нашия уебсайт, за да Ви обслужваме по-добре.</li>
                    <li>За да ни позволите да Ви обслужваме по-добре в отговор на Вашите заявки за обслужване на клиенти.</li>
                    <li>За администрираме конкурс, промоция, проучване или друга функция на сайта.</li>
                    <li>За да оцените услуги или продукти на наши клиенти, юридически лица явяващи се трета страна, за да може те от своя страна да подобрят своите продукти или услуги и отговорят на Ваши запитвания и/или оплаквания</li>
                    <li>За да се свържем с Вас в кореспонденция (по имейл или телефон)</li>
                </ul>
                <h3>КАК ЗАЩИТАВАМЕ ВАШАТА ИНФОРМАЦИЯ?</h3>
                <p>
                    Нашият уебсайт се проверява редовно за пропуски в сигурността и известни уязвимости, за да направим Вашето посещение на нашия сайт възможно най-безопасно. 
                    Използваме редовни проверки за зловреден софтуер. Вашата лична информация се съдържа в криптиран формат, според световните добри практики за защита на данни 
                    и е достъпна само от ограничен брой лица, които имат специални права за достъп до такива системи и са задължени да пазят информацията поверителна. В 
                    допълнение, цялата чувствителна информация, която предоставяте, е криптирана чрез технологията Secure Socket Layer (SSL). Ние прилагаме различни мерки за 
                    сигурност, когато потребител въвежда, изпраща или получава достъп до своята информация, за да поддържаме безопасността на Вашата лична информация.
                </p>
                <h3>КАКВО ПРЕДСТАВЛЯВАТ БИСКВИТКИТЕ И ВАШИТЕ ПРАВА ОТНОСНО ИЗПОЛЗВАНЕТО ИМ</h3>
                <p>
                    Бисквитките са широко използвана технология, състояща се от информация, която уебсайтовете изпращат и съхраняват на вашето устройство.
                </p>
                <p>
                    Уеб браузърите са отговорни за съхраняването на бисквитки и могат също така да помогнат за тяхното управление. Моля, консултирайте се с доставчика на 
                    вашия браузър за настройките, които Ви позволяват да блокирате нежелани бисквитки. Не можем да гарантираме, че нашият уебсайт ще функционира правилно 
                    през браузъри, където бисквитките са блокирани.
                </p>
                <p>
                    Имате право да оттеглите съгласието си за използването на бисквитки от наша страна по всяко време, като използвате нашата функция „Изтриване на моите данни“ под 
                    страницата на профила.
                </p>
                <h3>ВАШИЯТ НАЧИН ДА СТИГНЕТЕ ДО НАС</h3>
                <p>
                    Винаги можете да изпратите имейл до нашия DPO на privacy@cnts.bg за Вашите въпроси и искания относно Вашите права за защита на поверителността.
                </p>
                <h3>НАШАТА УПОТРЕБА НА БИСКВИТКИ</h3>
                <p>
                    Използваме създадена изцяло от нас, подписана и криптирана бисквитка, наречена „kazvabg_cookie“. Това е постоянна бисквитка, което означава, че 
                    има “срок на годност”, който в този случай е 1 година. Това е бисквитка изработена и администрирана изцяло от нас, така че трети страни не могат да 
                    я използват, за да идентифицират Вашето устройство.
                </p>
                <p>
                    Нашата бисквитка съдържа криптирана информация за потребителски идентификатор и ни помага да запомним Вашето устройство, когато се върне на нашия уебсайт. 
                    Това е от съществено значение за нашата услуга и ни позволява да Ви покажем Вашата история на изразено мнение по различни теми, да подобрим изживяването Ви, 
                    като запомним предпочитанията Ви, а също и да създадем нашите анализи на общественото мнение.
                </p>
                <h3>Строго необходима употреба на нашата бисквитка е наложителна, за:</h3>
                <ul>
                    <li>
                        Удостоверяване на потребителя между нашите различни услуги (технически термин, да не се бърка с услуги за потребителя)
                    </li>
                    <li>
                        Сигурност и цялост на данните за мнения, които събираме - за да гарантираме, че аналитичната информация, която предоставяме на потребителите и 
                        клиентите, е автентична и за да противодействаме на злонамерено поведение на нашата платформа.
                    </li>
                </ul>
                <h2>ЦЕЛ И ПРАВНО ОСНОВАНИЕ ЗА ОБРАБОТВАНЕ</h2>
                <ul>
                    <li>Съгласие – когато е получено ясно съгласие да бъдат обработвани лични данни за определена цел, напр. за участие маркетингови проучвания.</li>
                    <li>Договор – когато обработването на лични данни е необходимо за изпълнението на нашите задължения по договор.</li>
                    <li>Правно задължение – когато обработването на лични данни е необходимо за спазване на правно задължение, напр. за данъчни цели.</li>
                    <li>Законов интерес – когато обработването на лични данни обслужва основателен бизнес интерес, освен ако не е в интерес на лицето тази информация да бъде защитена.</li>
                </ul>
                <h2>ВАШИТЕ ПРАВА</h2>
                <p>
                    Инструмента kazva.bg има за своя най-първа цел да овласти всеки един свой потребител да използва бързо и лесно силата на своето мнение, така че да 
                    доведе до ефективна промяна в продуктите, услугите и инициативите на организациите, клиенти на kazva.bg Изградихме този инструмент за Вас, за да може 
                    Вашия глас да бъде чут. Наред с всичко това, физическите лица имат редица права по отношение на личните си данни, обработвани от ЦНТС, а именно:
                </p>
                <h3>Достъп до своите лични данни</h3>
                <p>
                    Имате правото да получите от нас потвърждение за това дали Вашите лични данни, касаещи Вас, се обработват, и, ако това е налице, да поискате достъп до личните данни.
                </p>
                <h3>Редакция на своите лични данни</h3>
                <p>
                    Имате правото да получите копие от личните данни, които преминават през обработка. Можем да поискаме допълнителна информация от Вас за 
                    идентифициране или за допълнителни копия, които сте поискали.
                </p>
                <p>
                    Имате право да получите от нас поправка на неточни лични данни, свързани с Вас. В зависимост от целите на обработването имате право да допълните 
                    непълни лични данни, включително и чрез предоставяне на допълнителна декларация.
                </p>
                <h3>Изтриване на своите лични данни (право да бъдете забравени)</h3>
                <p>
                    При определени обстоятелства е възможно да имате правото да поискате от нас да изтрием Вашите личните данни и ние сме длъжни да изтрием съответните 
                    лични данни. В такъв случай вече няма да можем да Ви предоставяме нашите услуги.
                </p>
                <h3>Право на ограничаване на обработката</h3>
                <p>
                    Имате право да поискате от нас ограничаване в обработването на Вашите лични данни. В този случай съответните данни ще бъдат маркирани и ще могат да се 
                    обработват от нас само за определени цели. 
                </p>
                <h3>Право на възражение и права, свързани с автоматизираното взимане на решения</h3>
                <p>
                    При определени обстоятелства е възможно да възразите спрямо обработването на Вашите лични данни, включително и спрямо профилирането, извършвано от нас, 
                    а ние сме задължени повече да не обработваме Вашите лични данни. В допълнение, ако Вашите лични данни се обработват предвид наш законен интерес, Вие имате 
                    правото да възразите по всяко време спрямо обработката на лични данни, касаещи Вас за съответната цел. Освен това, при определени обстоятелства, в случаите 
                    на автоматизирано взимане на индивидуални решения, Вие имате правото да се възползвате от човешка намеса. ЦНТС не прилага механизъм за автоматизирано взимане на решения.
                </p>
                <h3>Право на преносимост на данните</h3>
                <p>
                    Имате правото да получите своите лични данни, които сте ни предоставили, в структуриран често използван формат, който може да се разчете от 
                    машина (напр. в дигитален формат). Възможно е и да имате правото да поискате прехвърляне на тези данни към друго лице, без ние да възпрепятстваме 
                    това, ако такова прехвърляне е технически изпълнимо.
                </p>
                <h3>Право на оттегляне на съгласие</h3>
                <p>
                    Когато обработването на Вашите лични данни се основава на Ваше съгласие, можете да оттеглите това съгласие по всяко време. Оттеглянето на съгласието 
                    не засяга правото за обработване на данни, които сме получили чрез вашето съгласие, дадено преди оттеглянето.
                </p>
                <p>
                    Ако оттеглите съгласието си за обработване на Вашите лични данни, е възможно да не сме в състояние да Ви предоставим всички или част от услугите, които завявате.
                </p>
                <h3>Право на жалба</h3>
                <p>
                    Ако смятате, че не сме спазили задълженията си за защита на данните, се надяваме, че Вие ще пожелаете да се обърнете за разрешаването на този въпрос към нас 
                    като изпратите своето писмено искане или оплакване на имейл: privacy@cnts.bg
                </p>
                <h2>ДАННИ ЗА КОНТАКТ</h2>
                <p>
                    По отношение на въпроси, свързани със защитата на данните, за да упражните правата си, или за да подадете оплакване, моля 
                    използвайте една от следните форми за контакт:<br />
                    Имейл: privacy@cnts.bg<br />
                    Пощенски адрес: България, гр. София, ул. Дунав 44
                </p>
            </PolicyScreenStyle>
            <StyledButton onClick={props.backClicked}>
                <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faArrowLeft} /></span>
                {t('button_back')}
            </StyledButton>
        </div>
    );
};

export default PolicyScreen;