import styled from 'styled-components';

import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { AppContext, MenuScreen } from '../App';
import MatomoTracker from '../util/matomo/MatomoTracker';
import { useParams } from 'react-router-dom';
import { TopicV2 } from '@kazvabg/voterapp-model';

const OptionsButtonStyle = styled.button`
    color: white;
    font-size: 42px;
    background: #00000055;
    border: none;
    border-radius: 50px;
    padding: 10px;
    width: 65px;
    height: 65px;
    opacity: 0.5;
    cursor: pointer;

    position: absolute;
    right: 30px;
    bottom: 30px;

    ${(props: { absolute?: boolean }) => props.absolute ? `

    ` : `
        //margin-left: calc(100% - 65px);
    `}
`;

interface OptionsButtonProps {
    absolute?: boolean;
    topic?: TopicV2 | null;
}

const OptionsButton: React.FC<OptionsButtonProps> = (props) => {
    const { setMenuScreen } = useContext(AppContext);
    
    const { topicHash }: { topicHash: string } = useParams();

    const clickOptionsButton = () => {
        MatomoTracker.trackEvent({
            category: 'options-menu', 
            action: 'click-options-button',
            documentTitle: props.topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });
        setMenuScreen(MenuScreen.MainMenu)
    };

    return (
        <OptionsButtonStyle absolute={props.absolute} onClick={clickOptionsButton}>
            <FontAwesomeIcon icon={faGear} />
        </OptionsButtonStyle>
    );
};

export default OptionsButton;