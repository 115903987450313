import { useContext, useEffect, useRef, useState } from 'react';

import axios from 'axios';

import { TopicV2 } from '@kazvabg/voterapp-model';

import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../App';

import VoteEnd from './topic/VoteEnd';

import styled from 'styled-components';
import QuestionAndAnswer from './topic/QuestionAndAnswer';
import StyledContentPanel from '../components/StyledContentPanel';
import TypingTextV2 from '../components/TypingTextV2';
import SliderPanel from './topic/SliderPanel';
import { animated, useSpring } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { capitalizeFirst, fixBold } from '../util/util';

import { Helmet } from 'react-helmet';
import Greetings from './topic/Greetings';
import OptionsButton from '../components/OptionsButton';
import Fade from '../components/Fade';
import StyledButton from '../components/StyledButton';
import MatomoTracker from '../util/matomo/MatomoTracker';
import ConsentOverlay from '../components/ConsentOverlay';

// razmera na ikonite 28px
const VoteScreenContainer = styled(animated.div)`
    width: 100%; 
    display: flex;
    flex-direction: column; 
    transition: height 1s ease;
    
    ${(props: { topicstate: TopicState, showresults: string }) => props.topicstate === TopicState.Initial ? `
        height: 100%;
    ` : props.showresults === 'true' ? `
    
        height: calc(40vh + 200px);
        min-height: 500px;
    ` : `
        height: auto;
        margin-bottom: -10px;
    `}
`;

export enum VoteState {
    Initial = 'initial',
    Touched = 'touched',
    Moved = 'moved',
}

enum TopicState {
    Loading = 'loading',
    Greeting = 'greeting',
    Initial = 'initial',
    SliderTransform = 'slider-transform',
    VoteResults = 'vote-result', // containes first question
    QnA = 'qna', // after first question hide title and results
    EndScreen = 'vote-end'
}

enum TopicAnimationState {
    ContentPanelFadeIn = 'content-panel-fade-in',
    TypeTitleText = 'type-title-text',
    TypeSubTitleText = 'type-subtitle-text',
    SliderFadeIn = 'slider-fade-in',
    SubTitleReverse = 'subtitle-reverse',
    SubTitleTypeRating = 'subtitle-type-rating',
    TitleAndSliderFadeOut = 'title-and-slider-fade-out'
}

interface TopicScreenState {
    sliderActive: boolean;
    topicState: TopicState;
    topicAnimationState: TopicAnimationState;
    voteValue: number | null;
    voteId: number | null;
    showConsentOverlay: boolean;
}

const initState = {
    sliderActive: false,
    topicState: TopicState.Loading,
    topicAnimationState: TopicAnimationState.ContentPanelFadeIn,
    voteValue: null,
    voteId: null,
    showConsentOverlay: false
};

const TopicComponent: React.FC = () => {

    const { 
        apiUrl, topic, profile, setProfile,showResults,
        setTopic, setGlassPanelColor, setGlassPanelBackdropFilter, setLoading,
    } = useContext(AppContext);

    const { topicHash }: { topicHash: string } = useParams();
    const history = useHistory();

    const { i18n, t } = useTranslation();

    const [state, setState] = useState<TopicScreenState>(initState);

    const stateRef = useRef<TopicScreenState>(state);
    useEffect(() => { stateRef.current = state; }, [state]);

    const [voteScreenStyle, voteScreenApi] = useSpring(() => ({
        opacity: 1,
        config: { duration: 300 },
    }));

    const [titlePanelStyle, titlePanelApi] = useSpring(() => ({
        opacity: 0,
        config: { duration: 600 },
    }));

    const [sliderPanelStyle, sliderPanelApi] = useSpring(() => ({
        opacity: 0,
        flexGrow: 1,
        config: { duration: 600 },
    }));

    useEffect(() => {
        setGlassPanelColor('');
    }, []);

    //const wait = (milliseconds: number) => {
    //    return new Promise((resolve) => setTimeout(resolve, milliseconds));
    //};

    const shouldHaveGreetingMessage = (topic: TopicV2 | null) => {
        if (!topic) return false;

        const result: boolean =
            topic.greetingMessage.messages.length > 0 ||
            !!topic.greetingMessage.videoUrl ||
            !!topic.greetingMessage.mediaUrl;

        return result;
    };

    const getTopic = async () => {

        //const requestStartedTime = Date.now();
        const res = await axios.get(`${apiUrl}/topic/${topicHash}`);

        //const timeElapsed = Date.now() - requestStartedTime;
        //if (timeElapsed < 750)
        //    await wait(750 - timeElapsed);

        if(!profile && res.data.cookieExists) {
            const res = await axios.get(`${apiUrl}/user`);
            setProfile(res.data.profile);
            window._paq.push(['setUserId', res.data.profile.userId]);
        }

        if (res.data.origMsg === null) {
            history.push('/');
            return;
        }

        // analytics call
        MatomoTracker.trackPageView({
            documentTitle: res.data.origMsg.name,
            href: `https://kazva.bg/${topicHash}`
        });

        const topic = res.data.origMsg;
        topic.entityLogo = res.data.entityLogo;

        if (topic.disabled) {
            history.push('/');
            return;
        }

        setTopic(topic);

        if (!topic.image && !topic.backgroundUrl) {
            setLoading(false);
        }

        if (topic?.enforceLanguage) {
            i18n.changeLanguage(topic.enforceLanguage);
        } else if (topic) {

            const topicLanguages = [topic?.defaultLanguage, ...topic.altLngs];
            const currentLanguage = i18n.language;
            const supportedLanguages = ['en', 'bg', 'de'];

            // if topic does not provide your system language
            if (!topicLanguages.includes(currentLanguage)) {
                if (!topicLanguages.includes('en') &&
                    supportedLanguages.includes(topic.defaultLanguage)
                ) {
                    // topic does not provide english, but the app provides the topic default
                    i18n.changeLanguage(topic.defaultLanguage);
                } else {
                    // otherwise use english
                    i18n.changeLanguage('en');
                }
            } else {
                i18n.changeLanguage(currentLanguage);
            }
        }

        const shouldBeInGreetingState = shouldHaveGreetingMessage(topic);
        stateRef.current = { ...stateRef.current, topicState: shouldBeInGreetingState ? TopicState.Greeting : TopicState.Initial };
        setState(stateRef.current);

        setTimeout(() => {
            if (!shouldBeInGreetingState)
                startAnimations();

        }, 1000);
    };

    const animationsStartedRef = useRef<boolean>(false);

    useEffect(() => {
        if (stateRef.current.topicState === TopicState.Initial && !animationsStartedRef.current) {
            animationsStartedRef.current = true;
            startAnimations();
        }
    }, []);

    const startAnimations = () => {
        titlePanelApi.start({
            opacity: 1,
            onRest: () => {
                stateRef.current = { ...stateRef.current, topicAnimationState: TopicAnimationState.TypeTitleText };
                setState(stateRef.current);
            }
        });
    };

    const resetVote = () => {

        // analytics call
        MatomoTracker.trackPageView({
            documentTitle: topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        const shouldBeInGreetingState = shouldHaveGreetingMessage(topic);
        stateRef.current = { ...initState, topicState: shouldBeInGreetingState ? TopicState.Greeting : TopicState.Initial };
        setState(stateRef.current);

        voteScreenApi({ opacity: 1, immediate: true });
        sliderPanelApi({ opacity: 0, immediate: true });
        setGlassPanelColor('');

        titlePanelApi.start({
            from: { opacity: 0 },
            opacity: 1,
            onRest: () => {
                stateRef.current = { ...stateRef.current, topicAnimationState: TopicAnimationState.TypeTitleText };
                setState(stateRef.current);
            }
        });
    };

    useEffect(() => {
        getTopic();
    }, [topicHash]);

    const submitValue = (voteValue: number) => {
        stateRef.current = {
            ...stateRef.current,
            topicState: TopicState.SliderTransform,
            topicAnimationState: TopicAnimationState.SubTitleReverse,
            voteValue
        };
        setState(stateRef.current);
    };

    const submitVoteId = (voteId: number) => {
        stateRef.current = { ...stateRef.current, voteId };
        setState(stateRef.current);
    };

    const firstQuestionAnswered = () => {
        voteScreenApi.start({
            opacity: 0,
            onRest: () => {
                stateRef.current = { ...stateRef.current, topicState: TopicState.QnA };
                setState(stateRef.current);
            }
        });
    };

    const getVoteTitle = () => {
        let voteTitle = !topic ? '' : capitalizeFirst(topic.displayName ? topic.displayName : topic.name);

        const currentLanguage = i18n.language;

        if (currentLanguage !== topic?.defaultLanguage && topic?.altLngs.includes(currentLanguage)) {
            voteTitle = topic?.translations[currentLanguage].topicName;
        }

        return voteTitle;
    };

    const showVoteScreen =
        state.topicState === TopicState.Initial ||
        state.topicState === TopicState.VoteResults ||
        state.topicState === TopicState.SliderTransform;

    const showQnA =
        state.topicState === TopicState.VoteResults ||
        state.topicState === TopicState.QnA;

    const showEndScreen = state.topicState === TopicState.EndScreen;

    useEffect(() => {
        if (state.topicState === TopicState.SliderTransform && !showResults) {
            stateRef.current = { ...stateRef.current, topicState: TopicState.VoteResults };
            setState(stateRef.current);
        }
    }, [state.topicState]);

    const topicDisplayName = !topic ? '' : capitalizeFirst(topic.displayName ? topic.displayName : topic.name);
    //let ogDescription = `Виж какво казват другите спрямо твоето мнение с kazva.bg. Става за секунди и без инсталация.`;

    const [consentOverlayStyle, consentOverlayApi] = useSpring(() => ({
        opacity: 0,
        config: { duration: 600 },
    }));

    const greetingComplete = () => {
        stateRef.current = { ...stateRef.current, topicState: TopicState.Initial };
        setState(stateRef.current);

        titlePanelApi.start({
            from: { opacity: 0 },
            opacity: 1,
            onRest: () => {
                stateRef.current = { ...stateRef.current, topicAnimationState: TopicAnimationState.TypeTitleText }
                setState(stateRef.current);
            }
        });
    };

    const titleTypingComplete = () => {
        stateRef.current = { ...stateRef.current, topicAnimationState: TopicAnimationState.TypeSubTitleText };
        setState(stateRef.current);
    };

    const subtitleTypingComplete = () => {
        if (stateRef.current.topicAnimationState === TopicAnimationState.TypeSubTitleText) {
            setGlassPanelColor('');
            setGlassPanelBackdropFilter(40, 1, 1);
            sliderPanelApi.start({
                opacity: 1,
                onRest: () => {
                    stateRef.current = { ...stateRef.current,
                        topicAnimationState: TopicAnimationState.SliderFadeIn,
                        showConsentOverlay: ! profile,
                        sliderActive: !! profile
                    };
                    setState(stateRef.current);

                    if(!profile)
                        consentOverlayApi.start({
                            opacity: 1,
                        });
                }
            })
        } else if (stateRef.current.topicAnimationState === TopicAnimationState.SubTitleReverse) {
            stateRef.current = { ...stateRef.current, topicAnimationState: TopicAnimationState.SubTitleTypeRating };
            setState(stateRef.current);
        }
    };

    return (
        <>
            {!state.showConsentOverlay ? null :
                <animated.div style={{
                    ...consentOverlayStyle,
                    position: 'absolute',
                    top: 0,
                    minHeight: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    zIndex: 1000
                }}>
                    <ConsentOverlay topic={topic} onComplete={() => {
                        consentOverlayApi.start({
                            opacity: 0,
                            onRest: async () => {
                                const res = await axios.post(`${apiUrl}/user`);
                                stateRef.current = { ...stateRef.current, showConsentOverlay: false, sliderActive: true };
                                setState(stateRef.current);
                                setProfile(res.data.profile);
                                window._paq.push(['setUserId', res.data.profile.userId]);
                            }
                        });
                    }}/>
                </animated.div>
            }
            {!topic ? null :
                state.topicState === TopicState.Greeting
                    ? <Greetings topic={topic} onComplete={greetingComplete} /> :
                    <>
                        <Helmet>
                            <title>{topicDisplayName} - Казва БГ</title>
                            <link rel="canonical" href={`https://kazva.bg/${topicHash}`} />
                            <meta name="description" content={'Бъди чут със системата за обратна връзка на България - kazva.bg!'} />
                            {/*<meta property="og:url" content={`https://kazva.bg/${topicHash}`} />
                            <meta property="og:type" content="article" />
                            <meta property="og:title" content={`KAZVA.BG - ${topicDisplayName}`} />
                            <meta property="og:description" content={`"${ogDescription}"`} />
                            <meta property="og:image" content={`${ogImageUrl}`} />
                            <meta property="og:image:type" content={`"image/${isGif ? 'gif' : 'jpeg'}`} />
                            <meta property="og:image:width" content="750" />
                            <meta property="og:image:height" content="392" />
                            <meta property="fb:app_id" content="1178242265526787" />
                            <meta property="twitter:url" content={`${isGif ? ogImageUrl : `${templatesUrl}/${topicHash}.html`}`} />
                            <meta property="twitter:card" content="summary" />
                            <meta property="twitter:title" content={`KAZVA.BG - ${ogName}`} />
                            <meta property="twitter:description" content={`${ogDescription}`} />
                            <meta property="twitter:image" content={`${ogImageUrl}`} />*/}
                        </Helmet>
                        {!showVoteScreen ? null :
                            <VoteScreenContainer topicstate={state.topicState} style={voteScreenStyle} showresults={showResults.toString()}>
                                <animated.div style={{ padding: '20px', paddingBottom: '10px', ...titlePanelStyle }}>
                                    <StyledContentPanel>
                                        <TypingTextV2
                                            delay={10}
                                            active={state.topicAnimationState === TopicAnimationState.TypeTitleText}
                                            onComplete={titleTypingComplete}
                                        >
                                            <h1>{getVoteTitle()}</h1>
                                        </TypingTextV2>
                                        <TypingTextV2 active={
                                            state.topicAnimationState === TopicAnimationState.TypeSubTitleText ||
                                            state.topicAnimationState === TopicAnimationState.SubTitleReverse ||
                                            state.topicAnimationState === TopicAnimationState.SubTitleTypeRating
                                        }
                                            reverse={state.topicAnimationState === TopicAnimationState.SubTitleReverse}
                                            delay={10}
                                            onComplete={subtitleTypingComplete}
                                        >
                                            {stateRef.current.topicAnimationState === TopicAnimationState.SubTitleTypeRating
                                                ? <p className='auxiliary' style={{ marginTop: '10px' }}>
                                                    {fixBold(t('slider_subtitle_vote_value', { voteValue: (state.voteValue ?? 0) / 10 }))}
                                                </p>
                                                : <p className='auxiliary' style={{ marginTop: '10px' }}>{t('slider_subtitle')}</p>
                                            }
                                        </TypingTextV2>
                                    </StyledContentPanel>
                                </animated.div>
                                {state.topicState === TopicState.VoteResults && !showResults ? null :
                                    <animated.div style={{ position: 'relative', ...sliderPanelStyle }}>
                                        <SliderPanel
                                            sliderActive={state.sliderActive}
                                            voteValue={state.voteValue}
                                            voteId={state.voteId}
                                            submitValue={submitValue}
                                            submitVoteId={submitVoteId}
                                            onAnimationComplete={() => {
                                                stateRef.current = { ...stateRef.current, topicState: TopicState.VoteResults };
                                                setState(stateRef.current);
                                            }}
                                        />
                                    </animated.div>
                                }
                            </VoteScreenContainer>
                        }
                        {!showQnA ? null :
                            topic.qa.length === 0 ?
                                <div style={{ padding: '20px', }}>
                                    <Fade cascade>
                                        <StyledButton onClick={() => {
                                            stateRef.current = { ...stateRef.current, topicState: TopicState.EndScreen };
                                            setState(stateRef.current);
                                        }}>
                                            {t('button_continue')}
                                        </StyledButton>
                                        <OptionsButton />
                                    </Fade>
                                </div> :
                                <div style={{
                                    padding: '20px',
                                    minHeight: state.topicState === TopicState.QnA ? '100%' : 'auto',
                                    position: 'relative',
                                    paddingBottom: '100px'
                                }}>
                                    <QuestionAndAnswer
                                        voteValue={state.voteValue}
                                        voteId={state.voteId}
                                        firstQuestionAnswered={firstQuestionAnswered}
                                        setVoteEnd={() => {
                                            stateRef.current = { ...stateRef.current, topicState: TopicState.EndScreen };
                                            setState(stateRef.current);
                                        }}
                                    />
                                    <Fade>
                                        <p style={{
                                            margin: 0,
                                            fontSize: '14px',
                                            color: '#fff',
                                            width: 'calc(100% - 140px)',
                                            position: 'absolute',
                                            left: '30px',
                                            bottom: '30px',
                                            paddingLeft: '10px',
                                            paddingTop: '10px',
                                            lineHeight: 1.4
                                        }}>
                                            <b>kazva.bg</b> - {t('footer_title1')}<br />{t('footer_title2')}
                                            <br />
                                            {t('footer_learn_more')}
                                            {' '}
                                            <a style={{
                                                fontWeight: 'bold',
                                                color: 'white',
                                                textDecoration: 'underline'
                                            }}
                                                href="https://cnts.bg" target="_blank"
                                            >
                                                {t('footer_here')}
                                            </a>
                                        </p>
                                        <OptionsButton topic={topic} />
                                    </Fade>
                                </div>
                        }
                        {!showEndScreen ? null :
                            <div style={{ padding: '20px' }}>
                                <VoteEnd resetVote={resetVote} />
                            </div>
                        }
                    </>}
        </>
    );
};

export default TopicComponent;