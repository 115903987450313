import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import axios from 'axios';

axios.defaults.withCredentials = true;

// initialize matomo
const siteId = process.env.NODE_ENV === 'production' ? 1 : 2;

const _paq = window._paq = window._paq || [];
_paq.push(['disableCookies']);
_paq.push(['enableHeartBeatTimer']);
_paq.push(['enableLinkTracking']);

(function () {
    const u = "https://analytics.kazva.bg/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', `${siteId}`]);

    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    
    if(s.parentNode)
        s.parentNode.insertBefore(g, s);
})();

// render the app
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<App />);
