import React from 'react';

import { Answer } from '@kazvabg/voterapp-model';
import { SubmitAnswerFunction } from '../QuestionAndAnswer';
import Fade from '../../../components/Fade';
import { useTranslation } from 'react-i18next';
import StyledDropDown from '../../../components/StyledDropdown';

interface DropdownAnswerProps {
    question: string;
    answers: Answer[];
    submitAnswer: SubmitAnswerFunction;
}

export type SetImageFunction = (i: number, imageUrl: string) => void;
export type DeleteImageFunction = (i: number) => void;

const DropdownAnswer: React.FC<DropdownAnswerProps> = (props) => {

    const { t } = useTranslation();

    const submitAnswer = (e: React.ChangeEvent<HTMLSelectElement>, answer: Answer) => {
        props.submitAnswer({ answer: e.target.value, jump: answer.nextQuestionId });
    };

    const separateValues = (valuesString: string) => {
        return valuesString.split(';').map(str => str.trim());
    };

    return (
        <Fade>
            {props.answers.map((answer: Answer, i: number) =>
                <StyledDropDown key={i} style={{ fontSize: '16px', padding: '17px 20px', marginLeft: '-6px', width: 'calc(100% + 12px)' }} onChange={(e) => submitAnswer(e, answer)} value={t('label_please_choose')}>
                    <option disabled>{t('label_please_choose')}</option>
                    {separateValues(answer.text).map((value: string, i: number) => 
                        <option key={i} value={value}>{value}</option>
                    )}
                </StyledDropDown>
            )}
        </Fade>
    );
};

export default DropdownAnswer;