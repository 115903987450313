import { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import { AppContext } from '../App';
import TopicCard from '../components/TopicCard';

import { TopicV2 } from '@kazvabg/voterapp-model';
import TypingText from '../components/TypingText';
import StyledContentPanel from '../components/StyledContentPanel';
import { useHistory } from 'react-router-dom';
import { generateTopicSlug } from '../util/util';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import MatomoTracker from '../util/matomo/MatomoTracker';

export default () => {
    const [topics, setTopics] = useState<TopicV2[] | null>(null);
    const { apiUrl, setTopic, setGlassPanelColor, setGlassPanelBackdropFilter, setLoading } = useContext(AppContext);

    const history = useHistory();
    const { t } = useTranslation();

    const loadTopics = async () => {
        setLoading(true);

        const channelTopics = await axios.get(`${apiUrl}/channel/now/topics`);

        // analytics call
        MatomoTracker.trackPageView({
            documentTitle: 'Начало - Казва БГ',
            href: `https://kazva.bg`
        });

        setTopics(channelTopics.data.topics.filter((topic: TopicV2) => topic.name !== 'now'));
        setLoading(false);
    };

    useEffect(() => {
        setGlassPanelColor('black');
        setGlassPanelBackdropFilter(0, 1, 1);
        setTopic(null);
        loadTopics();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <Helmet>
                <title>Начало - Казва БГ</title>
                <link rel="canonical" href={`https://kazva.bg`} />
                <meta name="description" content={'Бъди чут със системата за обратна връзка на България - kazva.bg!'} />
            </Helmet>
            <StyledContentPanel style={{ marginBottom: '20px', backgroundColor: '#333' }}>
                <TypingText text={t('home_message')} delay={20} />
            </StyledContentPanel>
            {!topics ? null : topics.map((topic, i) => 
                <TopicCard key={i}
                    darkMode
                    topic={topic} 
                    onClick={() => { history.push(generateTopicSlug(topic.name)) }} 
                />
            )}
        </div>
    );
};
