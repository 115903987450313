import React, { useContext, useEffect, useRef, useState } from 'react';

import { AppContext } from '../../../App';

import styled from 'styled-components';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { animated, useSpring } from 'react-spring';
import ResultSlide from './ResultSlide';
import TotalResultSlide from './slides/MajorityResultSlide';
import { AgeGroup, GroupResult } from '@kazvabg/voterapp-model';
import GenderResultSlide from './slides/GenderResultSlide';
import AgeResultSlide from './slides/AgeResultSlide';
import { SliderAnimationState } from '../SliderPanel';

export const getAgeGroup = (birthYear: number | null): AgeGroup | null => {
    if (birthYear === null) return null;

    const voterAge: number = new Date().getFullYear() - birthYear;

    if (voterAge > 0 && voterAge < 12) return '0-11';
    if (voterAge > 11 && voterAge < 18) return '12-17';
    if (voterAge > 17 && voterAge < 25) return '18-24';
    if (voterAge > 24 && voterAge < 35) return '25-34';
    if (voterAge > 34 && voterAge < 45) return '35-44';
    if (voterAge > 44 && voterAge < 55) return '45-54';
    if (voterAge > 54 && voterAge < 65) return '55-64';
    if (voterAge > 64) return '65+';

    return null;
};

const VoteResultStyle = styled(animated.div)`
    width: calc(100% - 60px);
    height: 100%;
    display: inline-block;
    overflow: hidden;

    .slick-slider {
        height: calc(100% - 25px);
    }

    .slick-list {
        overflow: visible;
        width: 70%;
    }

    .slick-slide,
    .slick-list,
    .slick-track,
    .slick-slide > div {
        height: 100%;
    }

    ul.slick-dots {
        padding-right: 50px;
    }

    .slick-dots li button::before {
        font-size: 10px;
    }
`;

interface VoteResultProps {
    voteValue: number;
    voteId: number | null;
    sliderAnimationState: SliderAnimationState;
    onAnimationComplete: () => void;
    loadingResults: { [groupString: string]: boolean };
    groupResults: { [groupString: string]: GroupResult };
    setReferenceRating: (referenceRating: number | null, referenceColor: string | null) => void;
    submitGender: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    submitBirthYear: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface VoteResultState {
    currentSlide: number;
    fadeInComplete: boolean;
}

const VoteResult: React.FC<VoteResultProps> = (props) => {

    const { topic } = useContext(AppContext);

    const [state, setState] = useState<VoteResultState>(() => ({
        currentSlide: 0,
        fadeInComplete: false,
    }));

    const stateRef = useRef<VoteResultState>(state);

    const [resultContainerStyle] = useSpring(() => ({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duation: 600 },
        onRest: () => {
            stateRef.current = { ...stateRef.current, fadeInComplete: true };
            setState(stateRef.current);
        }
    }));

    useEffect(() => {
        const groupResult: GroupResult | undefined = props.groupResults[resultSlides[stateRef.current.currentSlide].resultKey];

        if (groupResult)
            props.setReferenceRating(groupResult.rating, groupResult.color);
        else
            props.setReferenceRating(null, null);

    }, [props.groupResults]);

    const sliderRef = useRef<Slider>(null);

    const slideClicked = (slideIndex: number) => {
        if (slideIndex === stateRef.current.currentSlide + 1)
            sliderRef.current?.slickNext();
    };

    useEffect(() => {
        if (state.fadeInComplete && topic && !topic.pvt.showPeopleStats)
            props.onAnimationComplete();
    }, [state]);

    const resultSlides: Array<{ resultKey: string, component: React.ReactNode }> = [];

    if (topic?.pvt.showPeopleStats)
        resultSlides.push({
            resultKey: 'majority',
            component:
                <TotalResultSlide
                    voteValue={props.voteValue}
                    groupResult={props.groupResults.majority}
                    fadeInComplete={state.fadeInComplete}
                    onAnimationComplete={props.onAnimationComplete}
                />,
        });

    if (topic?.pvt.showGenderStats)
        resultSlides.push({
            resultKey: 'gender',
            component:
                <GenderResultSlide
                    voteValue={props.voteValue}
                    groupResult={props.groupResults.gender}
                    submitGender={props.submitGender}
                />,
        });

    if (topic?.pvt.showAgeStats)
        resultSlides.push({
            resultKey: 'age',
            component:
                <AgeResultSlide
                    voteValue={props.voteValue}
                    groupResult={props.groupResults.age}
                    submitBirthYear={props.submitBirthYear}
                />,
        });

    /*if (topic?.pvt.showGenderByAgeStats)
        resultSlides.push({
            resultKey: 'ageGender',
            component:
                <AgeGenderResultSlide
                    voteValue={props.voteValue}
                    groupResult={props.groupResults.ageGender}
                />
        });*/

    return (
        <VoteResultStyle style={resultContainerStyle}>
            <Slider
                dots={true}
                arrows={false}
                infinite={false}
                speed={500}
                slidesToScroll={1}
                slide='article'
                ref={sliderRef}
                beforeChange={(oldIndex, newIndex) => {

                    const groupResult: GroupResult | undefined = props.groupResults[resultSlides[newIndex].resultKey];

                    if (groupResult)
                        props.setReferenceRating(groupResult.rating, groupResult.color);
                    else
                        props.setReferenceRating(null, null);

                }}
                afterChange={(index) => {
                    stateRef.current = { ...stateRef.current, currentSlide: index };
                    setState(stateRef.current);
                }}
            >
                {resultSlides.map((resultSlide, i) => (
                    <ResultSlide key={i} index={i} loadingResults={props.loadingResults[resultSlide.resultKey]}
                        groupResult={props.groupResults[resultSlide.resultKey]}
                        currentSlide={state.currentSlide} slideClicked={() => slideClicked(i)}
                    >
                        {resultSlide.component}
                    </ResultSlide>
                ))}
            </Slider>
        </VoteResultStyle>
    );
};

export default VoteResult;