
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useSpring } from "react-spring";

import styled from 'styled-components';
import { AppContext, GenderData } from "../../App";

import SmileyFaceDots from "./SmileyFaceDots";
import BetterSlider from "../../components/BetterSlider";
import { ratingColorGradient } from "../../util/util";
import { useLocation, useParams } from "react-router-dom";
import VoteResult, { getAgeGroup } from "./results/VoteResult";
import OptionsButton from "../../components/OptionsButton";
import { useTranslation } from "react-i18next";
import { GroupResult } from "@kazvabg/voterapp-model";
import MatomoTracker from "../../util/matomo/MatomoTracker";

const PartnerLogo = styled.div`
    position: absolute;
    text-align: right;
    right: 40px;

    p {
        left: 5px;
        bottom: 105px;
        width: 150px;
        font-size: 14px;
        color: black;
        margin: 0;
        color: #fff;
        opacity: 0.8;
    }

    img {
        left: 15px;
        bottom: 60px;
        width: 77px; 
        opacity: 1;
    }
`;

const SmileyFaceContainer = styled.div`
    position: absolute;
    left: 2px;
    margin-top: -10px;
    width: 215px;
    height: 215px;

    @media only screen and (max-width: 550px) {
        width: calc(100vw / 2 - 60px);
        height: calc(100vw / 2 - 60px);
    }
`;



export type SliderTouchedFunction = () => void;
export type SliderMovedFunction = (sliderValue: number) => void;
export type SubmitRatingFunction = (voteValue: number | null) => void;

export const RATING_COLORS = ['#e63939', '#ff4d40', '#c36eff', '#538cff', '#53b7ff', '#1ed931'];
//export const RATING_COLORS = ['#E21E28', '#614496', '#1E4389', '#1AA7C2', '#81BC7A', '#308F4B'];

export enum SliderAnimationState {
    Initial = 'initial',
    SliderTransform = 'slider-transform',
    ResultsFadeIn = 'results-fade-in'
}

enum VoteState {
    Initial = 'initial',
    SliderTouched = 'slider-touched',
    SliderMoved = 'slider-moved',
    SliderReleased = 'slider-released'
}

interface SliderPanelProps {
    sliderActive: boolean;
    voteValue: number | null;
    voteId: number | null;
    submitValue: (voteValue: number) => void;
    submitVoteId: (voteId: number) => void;
    onAnimationComplete: () => void;
}

interface SliderPanelState {
    voteState: VoteState;
    sliderAnimationState: SliderAnimationState;
    voteOpenTime: number | null;
    sliderTouchTime: number | null;
    referenceRating: number | null;
    referenceColor: string | null;
    loadingResults: { [groupString: string]: boolean };
    groupResults: { [groupString: string]: GroupResult };
}

const SliderPanel: React.FC<SliderPanelProps> = (props) => {

    const { apiUrl, topic, setGlassPanelColor, setGlassPanelBackdropFilter, profile, setProfile, preciseCoordinates } = useContext(AppContext);

    const { topicHash }: { topicHash: string } = useParams();
    const location = useLocation();

    const updateSmileyRef = useRef<(sliderValue: number) => void>();

    const { t } = useTranslation();
    const params: { topicHash: string } = useParams();

    const propsRef = useRef<SliderPanelProps>(props);
    useEffect(() => { propsRef.current = props; }, [props]);

    const animationCompleteCalled = useRef<boolean>(false);

    const [, api] = useSpring(() => ({
        color: '#6664',
        onChange: (values) => {
            setGlassPanelColor(values.value.color);
        }
    }));

    const [state, setState] = useState<SliderPanelState>({
        voteState: VoteState.Initial,
        sliderAnimationState: SliderAnimationState.Initial,
        voteOpenTime: null,
        sliderTouchTime: null,
        referenceRating: null,
        referenceColor: null,
        loadingResults: { majority: true, gender: true, age: true, ageGender: true },
        groupResults: {}
    });

    const stateRef = useRef<SliderPanelState>(state);

    useEffect(() => {
        if (topic) {
            stateRef.current = { ...stateRef.current, voteOpenTime: Date.now() };
            setState(stateRef.current);
        }
    }, [topic]);

    const loadInitialResults = async () => {

        if(!profile) return;

        const res = await axios.get(`${apiUrl}/topic/result/${topicHash}`, {
            params: {
                majority: true, gender: profile.gender, age: getAgeGroup(profile.birthYear)
            }
        });

        //const stateUpdate = () => {

        //};

        stateRef.current = {
            ...stateRef.current,
            loadingResults: { majority: false, gender: false, age: false, ageGender: false },
            groupResults: { ...stateRef.current.groupResults, ...res.data.groupResults }
        };
        setState(stateRef.current);

        //const timeSinceRequest = Date.now() - submitVoteRequestTimestampRef.current;

        /*if (timeSinceRequest < 2500) {
            setTimeout(stateUpdate, 2500 - timeSinceRequest);
        } else {
            stateUpdate();
        }*/
    };

    const loadExtraResults = async (gender: GenderData, birthYear: number | null) => {

        const requestStartedTimestamp = Date.now();

        const res = await axios.get(`${apiUrl}/topic/result/${topicHash}`, {
            params: {
                gender, age: birthYear ? getAgeGroup(birthYear) : undefined
            }
        });

        const stateUpdate = () => {

            const loadingResults = stateRef.current.loadingResults;

            for (const groupKey of Object.keys(res.data.groupResults)) {
                loadingResults[groupKey] = false;
            }

            stateRef.current = {
                ...stateRef.current,
                loadingResults,
                groupResults: { ...stateRef.current.groupResults, ...res.data.groupResults }
            };
            setState(stateRef.current);
        };

        const timeSinceRequest = Date.now() - requestStartedTimestamp;

        if (timeSinceRequest < 1500) {
            setTimeout(stateUpdate, 1500 - timeSinceRequest);
        } else {
            stateUpdate();
        }
    };

    const submitGender = async (e: React.ChangeEvent<HTMLSelectElement>) => {

        if(!profile) return;

        stateRef.current = {
            ...stateRef.current,
            loadingResults: {...stateRef.current.loadingResults,
                gender: true,
                ageGender: !!profile.birthYear
            },
        };
        setState(stateRef.current);

        const gender: GenderData = e.target.value as GenderData;
        await axios.put(`${apiUrl}/user`, {
            topicId: topic?.id,
            topicName: topicHash,
            gender,
            voteValue: props.voteValue
        });

        MatomoTracker.trackEvent({
            category: 'results', 
            action: 'submit-gender',
            documentTitle: topic?.name,
            href: `https://kazva.bg/${params.topicHash}`,
            customDimensions: [
                { id: 1, value: gender as string },
            ]
        });

        setProfile({ ...profile, gender });

        loadExtraResults(gender, profile.birthYear);
    };

    const submitBirthYear = async (e: React.ChangeEvent<HTMLSelectElement>) => {

        if(!profile) return;

        stateRef.current = {
            ...stateRef.current,
            loadingResults: {...stateRef.current.loadingResults,
                age: true,
                ageGender: !!profile.gender
            },
        };
        setState(stateRef.current);

        const birthYear: number = parseInt(e.target.value, 10);
        await axios.put(`${apiUrl}/user`, {
            topicId: topic?.id,
            topicName: topicHash,
            birthYear,
            voteValue: props.voteValue
        });

        MatomoTracker.trackEvent({
            category: 'results', 
            action: 'submit-birth-year',
            documentTitle: topic?.name,
            href: `https://kazva.bg/${params.topicHash}`,
            customDimensions: [
                { id: 2, value: birthYear.toString() },
            ]
        });

        setProfile({ ...profile, birthYear });

        loadExtraResults(profile.gender, birthYear);
    };

    const sliderTouched: SliderTouchedFunction = () => {
        //console.log('Slider touched', Date.now(), 'Pre-vote time: ', Date.now() - (state.voteOpenTime ?? 0));

        api.start({
            to: { color: '#fff8' },
            config: { duration: 2000 },
        });

        setGlassPanelBackdropFilter(40, 0, 1);

        if (state.voteState === VoteState.Initial) {
            stateRef.current = {
                ...stateRef.current,
                voteState: VoteState.SliderTouched,
                sliderTouchTime: Date.now()
            };
            setState(stateRef.current);
        }
    };

    const sliderMoved: SliderMovedFunction = (sliderValue: number) => {
        api.stop();

        if (updateSmileyRef.current)
            updateSmileyRef.current(sliderValue);

        let bgColor = '#808080';

        if (sliderValue) {
            bgColor = ratingColorGradient(RATING_COLORS, sliderValue);
        }

        setGlassPanelColor(bgColor + '4D');

        if (state.voteState !== VoteState.SliderMoved) {
            stateRef.current = { ...stateRef.current, voteState: VoteState.SliderMoved };
            setState(stateRef.current);
        }
    }

    //const submitVoteRequestTimestampRef = useRef<number>(0);

    const sliderReleased: SubmitRatingFunction = async (voteValue) => {
        //console.log('Slider released', Date.now(), 'Time in voting: ', Date.now() - (state.sliderTouchTime ?? 0));

        if (voteValue === null) {
            setGlassPanelBackdropFilter(40, 1, 1);
            api.start({ to: { color: '#6664' }, config: { duration: 500 }, });
            return;
        }

        stateRef.current = {
            ...stateRef.current,
            voteState: VoteState.SliderReleased,
            sliderAnimationState: SliderAnimationState.SliderTransform,
        };
        setState(stateRef.current);

        const timeBeforeVoting = state.sliderTouchTime === null || state.voteOpenTime === null ? 0
            : state.sliderTouchTime - state.voteOpenTime;
        const timeInVoting = state.sliderTouchTime === null ? 0 : (Date.now() - state.sliderTouchTime);

        //submitVoteRequestTimestampRef.current = Date.now();

        const searchParams = new URLSearchParams(location.search);
        const sources = searchParams.getAll('s').join(',');

        axios.post(`${apiUrl}/vote/${topic?.id}`, {
            topicName: topicHash,
            topicDisplayName: topic?.displayName,
            origin: document.referrer,
            vote: voteValue,
            timeBeforeVoting,
            timeInVoting,
            sources,
            preciseCoordinates
        }).then(res => {

            MatomoTracker.trackEvent({
                category: 'rating', 
                action: 'submit-rating',
                value: voteValue,
                documentTitle: topic?.name,
                href: `https://kazva.bg/${topicHash}`
            });

            props.submitVoteId(parseInt(res.data.voteId, 10));
            loadInitialResults();

            if (stateRef.current.sliderAnimationState === SliderAnimationState.ResultsFadeIn) {
                if (!animationCompleteCalled.current) {
                    animationCompleteCalled.current = true;
                    propsRef.current.onAnimationComplete();
                }
            }
            // at this point you should decide if you want to fade in QnA
            // it's either slider transform or results-fade-in
        });

        props.submitValue(voteValue);

        setTimeout(() => {
            stateRef.current = { ...stateRef.current, sliderAnimationState: SliderAnimationState.ResultsFadeIn };
            setState(stateRef.current);
        }, 1000);
    };

    const setReferenceRating = (referenceRating: number | null, referenceColor: string | null) => {
        stateRef.current = { ...stateRef.current, referenceRating, referenceColor };
        setState(stateRef.current);
    };

    return (
        <>
            {!(state.voteState === VoteState.Initial ||
                state.voteState === VoteState.SliderTouched ||
                state.voteState === VoteState.SliderMoved
            ) ? null :
                !topic?.entityLogo ? null :
                    <PartnerLogo>
                        <p>{t('slider_saying_to')}</p>
                        <img src={topic?.entityLogo} />
                    </PartnerLogo>
            }
            {state.voteState !== VoteState.SliderMoved ? null :
                <SmileyFaceContainer>
                    <SmileyFaceDots
                        updateSmileyRef={(updateSmiley: (sliderValue: number) => void) => updateSmileyRef.current = updateSmiley}
                    />
                </SmileyFaceContainer>
            }
            <BetterSlider
                sliderActive={props.sliderActive}
                sliderTouched={sliderTouched}
                sliderMoved={sliderMoved}
                sliderReleased={sliderReleased}
                shrink={props.voteValue ? true : false}
                referenceRating={state.referenceRating}
                referenceColor={state.referenceColor}
            />
            {state.voteState !== VoteState.Initial ? null :
                <OptionsButton absolute />
            }
            {state.sliderAnimationState !== SliderAnimationState.ResultsFadeIn ? null :
                <VoteResult
                    voteValue={props.voteValue ?? 50}
                    voteId={props.voteId}
                    onAnimationComplete={() => {
                        if (!animationCompleteCalled.current) {
                            animationCompleteCalled.current = true;
                            propsRef.current.onAnimationComplete();
                        }
                    }}
                    setReferenceRating={setReferenceRating}
                    sliderAnimationState={state.sliderAnimationState}
                    groupResults={state.groupResults}
                    submitGender={submitGender}
                    submitBirthYear={submitBirthYear}
                    loadingResults={state.loadingResults}
                />
            }
        </>
    );
}

export default SliderPanel;