import { TopicV2 } from '@kazvabg/voterapp-model';

import styled from 'styled-components';
import { capitalizeFirst } from '../util/util';

const TopicCardStyle = styled.button`
    width: 100%;;
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
    vertical-align: top;
    margin: 10px 0;
    text-decoration: none;
    color: #333;
    box-sizing: border-box;
    background-color: #00000055;
    color: white;
    border: none;
    padding: 0;
    cursor: pointer;
    text-align: left;

    img {
        width: 100%;
    }

    @media only screen and (max-width: 550px) {
        img {
            min-height: 48vw;
        }
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #999;
        margin: 0;
        padding: 20px 20px 20px 20px;
    }

    h2 {
        font-weight: 444;
        font-size: 22px;
        margin: 20px 0;
        padding: 10px 20px 15px 20px;
    }

    ${(props: { darkMode?: boolean }) => props.darkMode && `
        background-color: #333;
    `}
`;

export const TopicCardSkeleton = styled.div`
    width: calc(100% / 3 - 20px);
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
    vertical-align: top;
    margin: 10px;
    text-decoration: none;
    color: #000;
    height: 330px;
    background-color: white;
`;

interface TopicCardProps {
    darkMode?: boolean;
    topic: TopicV2;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TopicCard: React.FC<TopicCardProps> = props => {

    const voteTitle = props.topic.displayName ? capitalizeFirst(props.topic.displayName) : props.topic.name;

    const getThumbnailUrl = () => {
        if(props.topic.backgroundThumbnailUrl)
            return props.topic.backgroundThumbnailUrl;

        if(props.topic.backgroundUrl)
            return props.topic.backgroundUrl;

        return props.topic.image;
    };

    return (
        <TopicCardStyle darkMode={props.darkMode} onClick={props.onClick}>
            <img src={getThumbnailUrl()} />
            <h2><b>{voteTitle}</b></h2>
        </TopicCardStyle>
    );
};

export default TopicCard;