import React, { useEffect, useRef, useState } from 'react';

import { Answer } from '@kazvabg/voterapp-model';
import UploadPhoto from './UploadPhoto';
import { SubmitAnswerFunction } from '../QuestionAndAnswer';
import StyledButton from '../../../components/StyledButton';
import StyledTextArea from '../../../components/StyledTextArea';
import Fade from '../../../components/Fade';
import { useTranslation } from 'react-i18next';

interface AnswerOpenStructureProps {
    question: string;
    answers: Answer[];
    submitAnswer: SubmitAnswerFunction;
}

export type SetImageFunction = (i: number, imageUrl: string) => void;
export type DeleteImageFunction = (i: number) => void;

export default (props: AnswerOpenStructureProps) => {
    const [values, setValues] = useState<Array<{ text: string; image: string | null; }>>(props.answers.map(() => ({ text: '', image: null })));

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const { t } = useTranslation();

    const submitAnswer = (answer: Answer) => {
        if (values.length > 1) {

            let answersString = '';
            for (let i = 0; i < values.length; i++) {
                if (values[i]) {
                    answersString += `${!values[0].image? '' : values[0].image + ' '}${values[i].text}/%/`
                }
            }

            props.submitAnswer({ answer: answersString });
        } else if (values.length === 1) {
            const answerText = `${!values[0].image? '' : values[0].image + ' '}${values[0].text}`;
            props.submitAnswer({ answer: `${answerText.trim()}`, jump: answer.nextQuestionId });
        } else {
            props.submitAnswer({ answer: '' });
        }
    };

    const handleChange = (i: number, e: React.ChangeEvent<HTMLTextAreaElement>) => {
        values[i].text = e.target.value;
        setValues([...values]);

        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0';
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
            textAreaRef.current.style.overflowY = 'hidden';
        }
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0';
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
            textAreaRef.current.style.overflowY = 'hidden';
        }
    }, [textAreaRef.current]);

    const setImage: SetImageFunction = (i, imageUrl) => {
        values[i].image = imageUrl;
        setValues([...values]);
    };

    const deleteImage = (i: number) => {
        values[i].image = '';
        setValues([...values]);
    };

    return (
        <Fade cascade>
            {props.answers.map((answer: Answer, i: number) =>
                <label key={i}>
                    {answer.text}
                    <StyledTextArea ref={textAreaRef} onChange={e => handleChange(i, e)} placeholder={answer.placeholderText} />
                    {!answer.canAttachImage ? null : <UploadPhoto setImage={setImage} deleteImage={deleteImage} value={values[i]} number={i} />}
                    <p style={{
                        padding: '0 10px',
                        fontSize: '11px',
                        color: 'white',
                        opacity: 0.3,
                        marginBottom: '20px'
                    }}>
                        {t('qna_open_ended_disclaimer')}
                    </p>
                    <StyledButton onClick={() => submitAnswer(answer)}>{t('button_confirm')}</StyledButton>
                </label>
            )}
        </Fade>
    );
};