import React, { useContext, useState } from 'react';
import { AppContext } from '../App';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons/faCookieBite';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';

import StyledButton from '../components/StyledButton';
import StyledContentPanel from '../components/StyledContentPanel';
import { useTranslation } from 'react-i18next';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import PrivacyPolicy from './PrivacyPolicy';
import { TopicV2 } from '@kazvabg/voterapp-model';
import MatomoTracker from '../util/matomo/MatomoTracker';
import { useParams } from 'react-router-dom';

const LinkButton = styled.button`
    border: none;
    display: inline;
    background: none;
    color: #46d4ff;
    cursor: pointer;
    text-decoration: underline;
`;

const ConsentToggle = styled.div`
    margin: 20px 0 0 0;
    width: 100%;
    display: inline-block;
    color: white;

    .react-toggle {
        outline: none;

        .react-toggle-track {
            width: 100px;
            height: 48px;
            background: #00000026;

            box-shadow: 
                0px 3px 3px 0px #00000026 inset,
                0px 3px 3px 0px #8080802E inset,
                0px -0.5px 1px 0px #FFFFFF33 inset,
                0px -0.5px 1px 0px #5E5E5E4D inset;
        }

        .react-toggle-thumb {
            width: 40px;
            height: 40px;
            top: 4px;
            box-shadow: none;
            border: none;
            background-color: #eee;
            box-shadow: 0px 1px 1px 1px #00000038;
            left: 4px;
        }

        .react-toggle-track-check {
            width: 28px;
            height: 22px;
            left: 10px;
            svg {
                width: 28px;
                height: 22px;
            }
        }

        .react-toggle-track-x {
            width: 28px;
            height: 22px;
            right: 10px;
            svg {
                width: 28px;
                height: 22px;
            }
        }
    }

    .react-toggle--checked {
        
        .react-toggle-track {
            background: #0EFF1D54;
        }
        
        .react-toggle-thumb {
            left: 54px;
        }
    }

    label {
        display: inline-block;
        width: calc(100% - 110px);
        padding-left: 10px;
        vertical-align: top;
        font-size: 22px;
        padding-top: 10px;
    }
`;

const ConsentScreenComponent: React.FC<{ onComplete: () => void, topic: TopicV2 | null }> = (props) => {
    
    const { topicHash }: { topicHash: string } = useParams();

    const [state, setState] = useState({
        cookiesDenied: false,
        policyClicked: false
    });

    const { t } = useTranslation();

    const clickPolicy = () => {

        MatomoTracker.trackEvent({
            category: 'cookie-consent', 
            action: 'open-privacy-policy',
            documentTitle: props.topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        setState({ ...state, policyClicked: true });
    };

    const allowCookies = () => {
        
        MatomoTracker.trackEvent({
            category: 'cookie-consent', 
            action: 'allow-cookies',
            documentTitle: props.topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        props.onComplete();
    };

    const denyCookies = () => {

        MatomoTracker.trackEvent({
            category: 'cookie-consent', 
            action: 'deny-cookies',
            documentTitle: props.topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        setState({ ...state, cookiesDenied: true, policyClicked: false });
    };

    const goBack = () => {
        setState({ ...state, cookiesDenied: false, policyClicked: false });
    };

    return (
        state.policyClicked ?
            <PrivacyPolicy backClicked={goBack} /> :
        state.cookiesDenied ?
            <div style={{ padding: '20px' }}>
                <StyledContentPanel>
                    <h1>
                        <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faXmark} /></span>
                        {t('cookie_consent_title_denied')}
                    </h1>
                    <p>{t('cookie_consent_text_denied')}</p>
                </StyledContentPanel>
                <StyledButton onClick={goBack} style={{ marginTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faArrowLeft} /></span>
                    {t('button_back')}
                </StyledButton>
            </div> :
            <div style={{ padding: '20px' }}>
                <StyledContentPanel>
                    <h1>
                        <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faCookieBite} /></span>
                        {t('cookie_consent_title')}
                    </h1>
                    <p>
                        {t('cookie_consent_text')}
                    </p>
                    <p>
                        {t('cookie_consent_according')} <LinkButton onClick={clickPolicy}>{t('menu_main_privacy_notice')}</LinkButton>
                    </p>
                </StyledContentPanel>
                <StyledButton onClick={allowCookies} style={{ marginTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faCheck} /></span>
                    {t('cookie_consent_allow_cookie')}
                </StyledButton>
                <StyledButton onClick={denyCookies} style={{ marginTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faXmark} /></span>
                    {t('cookie_consent_deny_cookie')}
                </StyledButton>
            </div>
    );
}

export default ConsentScreenComponent;